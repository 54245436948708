import request from '@/utils/request';

//意見回饋
export function sendFeedback({ title, content }) {
  return request({
    url: 'feedback',
    method: 'post',
    data: {
      title,
      content
    }
  });
}

export default {
  sendFeedback
};
