<template>
  <div class="main-content white">
    <div class="edit-msg">
      <label>標題</label>
      <div class="input-wrap">
        <input type="text" placeholder="請輸入標題" v-model="title" />
      </div>
    </div>
    <div class="edit-msg">
      <label>問題描述</label>
      <div class="input-wrap">
        <textarea
          placeholder="請輸入問題描述"
          style="min-height:270px;"
          v-model="content"
        ></textarea>
      </div>
    </div>
    <div class="bottom-hint-block">
      若系統發信伺服器異常也可直接寄信至
      <br />
      『 連絡信箱：
      <a href="mailto:cs.ezbuy@gmail.com">cs.ezbuy@gmail.com</a>
      』
    </div>
    <div class="bottom-btn-block nofloat">
      <div class="btn-cta" @click="submit">
        送出
      </div>
    </div>
    <MessageAlert
      :show="showModal"
      @close-modal="confirmMsg"
      :msgTitle="msgTitle"
      :msgContent="msgContent"
    ></MessageAlert>
  </div>
</template>

<script>
import MessageAlert from '@/components/Modals/ModalMessageAlert';
import { sendFeedback } from '@/api/feedback';
export default {
  components: {
    MessageAlert
  },
  data() {
    return {
      showModal: false,
      msgTitle: null,
      msgContent: null,
      title: null,
      content: null
    };
  },
  computed: {
    validForm() {
      //驗證表單資料正確
      if (this.title && this.content) {
        return true;
      }
      return false;
    }
  },
  methods: {
    confirmMsg() {
      this.reset();
    },
    reset() {
      this.showModal = false;
      this.msgTitle = null;
      this.msgContent = null;
      this.title = null;
      this.content = null;
    },
    showSuccessMsg() {
      this.showModal = true;
      this.msgTitle = '成功送出';
      this.msgContent = '感謝您的寶貴意見';
    },
    showErrMsg() {
      this.showModal = true;
      this.msgTitle = '錯誤';
      this.msgContent = '請確認有正確填寫內容';
    },
    getPostData() {
      return {
        title: this.title,
        content: this.content
      };
    },
    submit() {
      if (!this.validForm) {
        this.showErrMsg();
        return;
      }

      sendFeedback(this.getPostData()).then(() => {
        this.showSuccessMsg();
      });
    }
  }
};
</script>
